.dialog{
    animation: slide-in 0.5s;
    transition: all 0.5s ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateY(400px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}