.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 1.1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-span {
  width: 70%;
  height: 1.1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-label {
  width: 20%;
  height: 0.8rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.skeleton-input {
  width: 100%;
  height: 1.8rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-footer {
  width: 30%;
}